<template>
  <div>
    <input
        :style="wrapperStyle"
        class="input"
        :class="{'error-st': errorMsg}"
        ref="input"
        :type="['biz', 'nickname'].includes(type) ? 'text' : type"
        v-model="inputValue"
        min="0"
        :maxlength="maxLength"
        @keyup.enter="$emit('onEnter')"
        :placeholder="placeholder"
        @input="e => debouncedOnKeyup(e.target.value)"
        autocomplete="new-password"
        :disabled="disabled"/>
        <div class="lp-error-msg" v-if="validate && validate.error_msg">{{ validate.error_msg }}</div>
        <div class="desc" v-if="desc">{{ desc }}</div>
  </div>
</template>
<script>
  import FormMixin from "@/mixins/FormMixin";
  import _ from 'lodash'; // lodash 라이브러리 추가

  export default {
    name: "LpText",
    mixins: [
      FormMixin
    ],
    props: {
      value: {
        type: [String, Number]
      },
      type: {
        type: String,
        default: 'text'
      },
      disabled: {
        type: Boolean,
        required: false
      },
      maxLength: {
        type: Number,
        required: 100
      },
      placeholder: {
        type: String,
        default: ''
      },
      errorMsg: {
        type: String,
        required: false,
        default: ''
      },
      width: {
        type: String
      },
      desc: {
        type: String,
        required: false,
        default: ''
      },
      validate: {
        type: Object,
        default: function() {
          return {
            valid_required: false,
            error_msg: '',
            max_length: 100,
            min_length: 0,
            alpha_s: true,
            alpha_g: true,
            special: true,
            num: true
          }
        }
      }
    },
    created() {
      if(this.value) {
        this.inputValue = this.value;
        if(this.type === 'number') {
          if(typeof(this.inputValue) === String) {
            this.inputValue = this.inputValue.replace(/-/gi, '');
          }
        }
      }
      if(this.validate) {
        this.valid = this.validate;
      }
      if(this.type === 'nickname') {
        this.debouncedOnKeyup = _.debounce(this.onKeyup, 200);
      } else {
        this.debouncedOnKeyup = this.onKeyup;
      }
    },
    watch: {
      value(n) {
        this.inputValue = n;
      }
    },
    data() {
      return {
        inputValue: '',
        currentRequest: null,
        previousRequest: null
      }
    },
    computed: {
      wrapperStyle() {
        let style = {};
        if(this.width) {
          style.width = this.width;
        }
        return style;
      }
    },
    methods: {
      onKeyup(value) {
        this.$emit('update:errorMsg', '');
        this.inputValue = value;
        if(this.type==='phone') {
          this.inputValue = this.autoHyphenPhone(value);
        } else if(this.type==='biz') {
          this.inputValue = this.autoHyphenBiz(value);
        } else if(this.type==='nickname') {
          console.log('nickname', value);
          this.checkNickname(value);
        }
        this.$emit('update:value', this.inputValue);
      },
      checkNickname(value) {
        if(this.user.email.split('@')[0] === value) {
          this.valid.error_msg = '이메일에서 사용된 이름은 사용할 수 없습니다.';
          return;
        }

        if (this.currentRequest) {
          this.currentRequest.cancel('Operation canceled due to new request.');
        }
        const CancelToken = this.$axios.CancelToken;
        const source = CancelToken.source();
        this.currentRequest = this.$axios.post('public/check_nickname', {
          nickname: value,
          user_id: this.user.user_id
        }, {
          cancelToken: source.token
        }).then(res => {
          if(res.data.result === 'already') {
            this.valid.error_msg = '이미 사용중인 닉네임입니다.';
          } else if(res.data.result === 'name') {
            this.valid.error_msg = '상호명과 동일한 이름은 사용할 수 없습니다.';
          }  else {
            this.valid.error_msg = '';
          }
        }).catch(error => {
          if (this.$axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            // handle error
          }
        });

        this.currentRequest = source;
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  input
    height 48px

  .error-st
    border 1px solid $error !important

  input:disabled 
    color $sub3

  .lp-error-msg
    color #e80000
    font-size 13px
    margin-top 4px
    margin-bottom 12px
    width: 100%

  .desc
    margin-top 8px
    font-size 13px
    color $sub2
</style>